@import "@vmf/components/src/assets/style/_index.scss";








































































































pre {
    padding: $padding;
    background-color: $gray8;
    color: #fff;
    overflow: auto;
}

.drag-item {
    padding: $padding;
    background-color: $gray2;

    + .drag-item {
        margin-top: $margin;
    }
}
