@import "@vmf/components/src/assets/style/_index.scss";













































.map-wrap {
    position: relative;
    flex: 1 1 1%;
    min-height: 100%;
    > .map {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
.page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    > .body {
        flex: 1 1 1%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
    }
}
